:root {
  --pv-color-dark-rgb: 64, 72, 79;
  --pv-color-light-rgb: 255, 255, 255;
  --pv-color-grey_1-rgb: 250, 249, 249;
  --pv-color-grey_2-rgb: 238, 244, 247;
  --pv-color-grey_3-rgb: 225, 228, 230;
  --pv-color-grey_4-rgb: 182, 195, 204;
  --pv-color-grey_5-rgb: 109, 125, 135;
  --pv-color-secondary-rgb: 10, 190, 101;
  --pv-color-primary-rgb: 6, 128, 255;
  --pv-color-attention-rgb: 247, 168, 49;
  --pv-color-footer-rgb: 64, 72, 79;
  --pv-color-footer-text-rgb: 255, 255, 255;
  --pv-font-size-h4: 17px;
  --pv-font-size-h6: 15px;
  --pv-font-size-h7: 14px;
  --pv-font-size-b1: 15px;
  --pv-font-size-b3: 13px;
  --pv-font-size-c1: 11px;
  --pv-font-family: "Open Sans", "Arial", sans-serif;
  --pv-letter-spacing-normal: 0.03em;
  --pv-line-height-normal: 1.4;
}
